@import 'components/shared/FormControl/FormControl.module.scss';
@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/utils.scss';

.inputContainer {
  position: relative;
  @include formControlBasicStyle();
}
.fieldSet {
  position: absolute;
  top: -10px;
  padding-top: 0;
  margin: 0;
  margin-top: 0;
  padding-right: 25px;
  min-height: 48px;
  min-width: 100%;

  @include formControlBasicStyle();
}

.inputNumber {
  min-height: 36px;
  padding-left: 10px;
  padding-right: 25px;
  min-width: 100%;
  @include formControlBasicStyle();
  @include formControlFocusStyle();

  // Hides the original styles
  outline: none;
  border: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  // Remove the "x" character on IE
  &::-ms-clear {
    display: none;
  }
}

.inputNumberDisabled {
  cursor: not-allowed;
}

.error {
  @include formControlErrorStyle();
}

.arrowsContainer {
  display: flex;
  flex-direction: column;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  justify-content: space-between;
}

.arrow {
  user-select: none;
  width: 25px;
  cursor: pointer;
  height: 50%;
  transition: all 0.2s ease-in-out;
  @include flex-center();
  &:hover {
    background-color: $border-color;
  }
  &:active {
    transition: none;
    background-color: $zoom-color;
    > * {
      fill: $white;
    }
  }
}
.arrowTop {
  border-top-right-radius: 3px;
}
.arrowBottom {
  border-bottom-right-radius: 3px;
}

.arrowDisabled {
  cursor: not-allowed;
  opacity: 0.3;
  &:hover {
    background-color: initial;
  }
}
