@import 'assets/scss/mixins/breakpoints.scss';
.root {
  display: flex;
  align-items: center;
  @include xs {
    margin-top: 5px;
    flex-direction: column;
    align-items: flex-start;
  }

  .heading {
    @include xs {
      margin-top: 0;
    }
  }

  .textStyling {
    @include xs {
      font-size: $medium-font;
    }
  }

  .textStyling.lineHeight {
    line-height: 21px;
    @include xs {
      line-height: initial;
    }
  }

  .alignEnd {
    align-items: flex-end;
  }

  .productTotalPrice {
    display: flex;
    align-items: flex-start;
  }

  .row {
    flex-direction: row;
  }

  .column {
    flex-direction: column;
  }

  .reverseOrder {
    order: 1;
  }

  .mr10 {
    margin-right: 10px;
  }
}

.priceSm {
  font-size: $fsXl;
}
.priceMd {
  font-size: $fs2xl;
}
