@import 'assets/scss/variables.scss';
.starStyle {
  font-size: $sm;
  color: $light-gray;
  letter-spacing: 3px;
  &.mainProductRating {
    font-size: $xlg;
    line-height: normal;
  }
  &.iconRatingHalf {
    color: $dark-gray;
  }
  & > span {
    cursor: default;
  }
}
