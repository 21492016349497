@import 'assets/scss/variables.scss';

.priceInfo {
  color: $tool-tip;
  font-size: $medium-font;
  padding-top: 7px;
  display: block;
  margin-bottom: -5px;
}
.priceToolInfo {
  background: $tooltip-bg;
  width: 160px;
  text-align: right;
  border-radius: 3px;
  box-shadow: 0 1px 2px 1px rgba(187, 155, 155, 0.1);
  p {
    font-size: 10px;
    color: $light-gray;
    margin-bottom: 0;
    justify-content: flex-end;
    display: flex;
    > .success {
      color: $success-color;
    }
    > .danger {
      color: $link-color;
    }
    span {
      a {
        font-size: 10px;
      }
      &:first-child {
        flex: 0 0 60%;
        max-width: 60%;
      }
      &:last-child {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
  }
  .borderTop {
    border-top: 1px solid $border-color;
    padding-top: 2px;
    margin-top: 2px;
    font-weight: bold;
  }
}
