@import 'assets/scss/variables.scss';
$border: '0px 0px 0px 1px';

@mixin formControlBasicStyle() {
  transition: all 0.2s ease-in-out;
  width: 100%;
  border-radius: 3px;
  outline: none;
  font-size: $body-font;
  border: 1px solid $input-border;
  line-height: 1.5;
}

@mixin formControlFocusStyle() {
  &:focus {
    box-shadow: #{$border} $dark-gray;
    @content;
  }
}

@mixin formControlErrorStyle() {
  box-shadow: #{$border} $link-color;
  background: rgba(204, 0, 0, 0.1);
  &:focus {
    box-shadow: #{$border} $link-color;
  }
}
