@import 'assets/scss/mixins/breakpoints.scss';
.noteWrapper {
  display: flex;
  align-items: center;
  border: 1px solid $input-border;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  padding: 0 10px;
  transition: all 0.3s ease-in-out 0s;
  .noBoxShadow {
    box-shadow: none;
  }
  textarea {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    height: 34px;
    resize: none;
    overflow: hidden;
    white-space: nowrap;

    @include xs {
      white-space: normal;
      overflow-y: auto;
    }
    &:focus {
      border: 0;
    }
  }
  .notePrepend {
    display: flex;
    align-items: center;
    span {
      font-size: 17px;
      color: $zoom-color;
      margin-right: 10px;
    }
    .userId {
      border-radius: 4px;
      background-color: $dark-gray;
      padding: 2px 8px;
      display: flex;
      margin-right: 10px;
      span {
        line-height: normal;
      }
    }
  }
  &:focus-within,
  &.active {
    border-color: $light-gray;
    span {
      color: $link-color;
    }
  }
  > div:last-child {
    flex-grow: 1;
    @include xs {
      width: 100%;
    }
  }
  @include xs {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
  }
}
.submit-linenote {
  display: none;
}
