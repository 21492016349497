@import 'assets/scss/mixins/breakpoints.scss';
.labelText {
  & > *:first-child {
    padding-right: 5px;
  }
  // Not sure this used, to clarify ⬇️
  &:not(:last-child) {
    margin-right: 30px;
  }

  .labelTextSection {
    > * {
      @include sm {
        line-height: 12px;
      }
    }
  }
}
