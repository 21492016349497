@import 'assets/scss/mixins/breakpoints.scss';
.productCardBodyContent {
  > * {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  [data-customwidth='customWidth'] {
    margin: 15px 0;
    div {
      &:first-child {
        width: 100%;
      }
    }
  }
  .productCardText {
    > div {
      > :nth-of-type(2) {
        display: flex;
        align-items: center;
        span {
          &:last-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 420px;
            @include max-md {
              max-width: 240px;
            }
            @include xxs {
              max-width: 140px;
            }
          }
        }
      }
    }
    &.memberPrice {
      min-height: 22px;
    }
  }
  &.listView,
  &.listViewXl {
    @include xs {
      padding-top: 5px;
      display: none;
    }
  }
  .productContentInfo {
    .purchaseWrapper {
      padding: 0 0 12px;
      display: flex;
      align-items: center;
      @include sm {
        align-items: start;
      }
      .iconWrapper {
        font-size: 16px;
        padding-right: 5px;
        &.primary {
          color: $link-color;
        }
        @include sm {
          line-height: 20px;
        }
      }
      > * {
        cursor: default;
        > * {
          cursor: default;
        }
      }
    }
    .infoLabelWrapper {
      padding: 0 0 10px;
    }
  }
}
