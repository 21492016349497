@import 'assets/scss/variables.scss';

.radioStarHidden {
  display: none;
  position: absolute;
  margin-left: -9999;
  color: $empty-star-color;
}

.label {
  margin: 0;
  line-height: normal;
}

.hoverStar {
  &:focus,
  &:hover {
    span {
      color: $dark-gray;
    }
  }
  &:focus ~ &,
  &:hover ~ & {
    span {
      color: $dark-gray;
    }
  }
}

.withFullStar {
  span {
    color: $dark-gray;
  }
}
