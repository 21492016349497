@import 'assets/scss/variables.scss';
.productHeading {
  > a {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 41px;
    sup {
      vertical-align: top;
    }
  }
  &.listView,
  &.listViewXl,
  &.listViewLg {
    > a {
      height: auto;
      p {
        margin: 0;
      }
    }
  }
  &.gridViewSm {
    padding: 7px 0;
    > a {
      height: 34px;
      line-height: 16px;
    }
  }
  .productTitle {
    & > :first-child {
      justify-content: start;
    }
  }
}
