@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.addToCartButton {
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 130px;
  display: flex;
  justify-content: center;
}

.numberInput {
  max-width: 70px;
  min-width: 54px;
  width: 30%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.addToCartButtonWrapper {
  button {
    min-width: auto;
    padding: 0 5px;
    @include min-md() {
      min-width: 130px;
    }
    @include min-lg() {
      min-width: 120px;
    }
  }
}
