@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

@mixin hoverDiv($property) {
  [data-product-footer='hidden'] {
    display: $property;
  }
}

@mixin default-padding() {
  padding: 20px 15px;
}
.productCard {
  &.overflowInListView {
    overflow: inherit;
  }
  width: 100%;
  position: relative;
  padding-bottom: 45px;
  height: 100%;
  overflow: hidden;

  @include print {
    height: auto;
  }

  .productFooterWrapper {
    &.gridView {
      > div {
        @include min-xs {
          @include hoverDiv(none);
        }
        @include xs {
          padding: 0 12px 16px;
          h4 {
            display: none;
          }
        }
      }
    }
    &.disableFooterHover {
      &.gridView {
        > div {
          > div {
            transform: none;
            transition: none;
            @include hoverDiv(block);
          }
        }
      }
    }
  }
  &:hover {
    z-index: 1;
  }
  // Everything that has to do with list views
  &.listView,
  &.listViewLg,
  &.listViewXl {
    [data-product-card-footer] [data-product-availability] {
      display: none;
    }
    display: flex;
    padding-bottom: 0;
    @include xs {
      min-height: 250px;
    }
    .productFooterWrapper {
      margin-right: auto;
    }
  }
  &.listViewLg {
    overflow: inherit;
    .productFooterWrapper {
      display: flex;
      flex-direction: row;
      @include sm {
        flex-basis: 100%;
      }
      @include print {
        flex-basis: 20%;
      }
    }

    @include sm {
      flex-wrap: wrap;
    }
    @include print {
      flex-wrap: nowrap;
    }
  }
  &.listViewXl {
    &.mb0 {
      margin-bottom: 0;
    }
  }
  // Everything about grid-view
  &.gridViewSm {
    border-radius: 4px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    &.gridViewSmContent {
      padding-bottom: 20px;
    }
    .productDetail {
      padding-top: 0;
    }
  }
  @include min-xs {
    .productFooterWrapper {
      &.disableFooterHover {
        > div {
          @include hoverDiv(block);
          > div {
            border-top: 1px solid $card-border;
            padding: 12px;
            transform: translateY(0);
            transition: transform 0.7s;
            bottom: 0;
            @include hoverDiv(block);
          }
        }
      }
    }

    .footerOverlayEffect {
      box-shadow: 0 0 11px $box-shadow;
      transition: all 0.3s ease-in-out 0s;
      &.gridView {
        > div > div {
          padding: 12px;
          transform: translateY(0);
          transition: transform 0.7s;

          bottom: 0;

          @include hoverDiv(block);
          h4 {
            display: none;
          }
        }
      }
      &.gridViewSm {
        > div {
          border-top: 1px solid $card-border;
          padding: 7px 12px 12px;
          transform: translateY(0);
          transition: transform 0.7s;
          bottom: 0;
          @include hoverDiv(block);

          h4 {
            display: none;
          }
        }
      }
      &.disableFooterHover {
        > div {
          @include hoverDiv(block);
        }
      }
    }
  }
  // Utility classes
  &.overFlowVisible {
    &:hover {
      overflow: visible;
    }
  }
  .productFooterWrapper {
    &.listViewXl {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $card-border;
      @include min-xs {
        padding: 1px;
      }
    }
    &.gridView {
      > div {
        @include min-xs {
          @include hoverDiv(none);
        }
        @include xs {
          padding: 12px 16px;
          h4 {
            display: none;
          }
        }
      }
    }
    &.gridViewSm {
      margin-top: auto;
    }
  }
}

.productFooter {
  padding: 7px 15px;
  border-top: 1px solid $card-border;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: $white;
  top: auto;

  @include print {
    width: auto;
    flex-basis: none;
  }

  &.gridView {
    position: absolute;
    bottom: 0;
    left: 0;
    @include min-xs {
      transform: translateY(100%);
      bottom: 45px;
      transition: transform 0.7s;
    }
    .loginInfo {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .restrictedIcon {
    > div {
      min-height: 0;
      padding-bottom: 0px;
      display: flex;
      padding-right: 5px;
      align-items: center;
      padding-top: 0;
    }
    display: flex;
    align-items: center;
    > span {
      color: $link-color;
      margin-right: 5px;
    }
  }

  .addToCart {
    @include min-xs {
      margin-top: 0;
    }
  }

  &.listView,
  &.listViewXl {
    margin-left: auto;
    min-width: 232px;
    max-width: 232px;
    border-left: 1px solid $card-border;
    text-align: right;
    border-top: 0;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    @include lg {
      min-width: 192px;
    }
    @include md {
      min-width: 160px;
      padding: 20px 6px;
    }
    @include sm {
      min-width: 160px;
      padding: 20px 10px;
    }
    @include xs {
      align-items: flex-start;
      position: absolute;
      right: 0;
      bottom: 20px;
      width: 55%;
      border: 0;
      min-width: auto;
      max-width: 100%;
      height: 145px;
      background: transparent;
      button {
        min-width: auto;
        padding: 8px 4px;
        font-size: $sm;
      }
      input {
        width: 28px;
        font-size: $sm;
      }
    }
    .addToCart {
      margin-top: auto;
    }

    h3 {
      padding-right: 0;
    }

    .productFooterInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      @include xs {
        align-items: flex-start;
        margin-top: 5px;
      }
    }
  }
  &.gridViewSm {
    position: absolute;
    bottom: 0;
    height: auto;
    padding: 0 15px 5px;
    border-top: 0;
    @include min-xs {
      transform: translateY(100%);
      bottom: 25px;
      transition: transform 0.7s;
    }
    h3 {
      font-size: $xlg;
      font-weight: normal;
    }
  }
  &.listViewLg {
    display: flex;
    align-items: flex-end;

    text-align: right;
    border-top: 0;
    padding: 0;
    flex-direction: row;
    h3 {
      padding-right: 0;
      @include sm {
        font-size: $fsXl;
      }
    }
    .productFooterInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      padding: 20px 15px;
      border-left: 1px solid $card-border;
      max-width: 145px;
      min-width: 145px;
      align-content: stretch;
      .productListViewContainer {
        @include xs {
          align-items: flex-end;
        }
      }
      @supports (-ms-ime-align: auto) {
        @include min-sm {
          min-height: 100%;
          max-height: 270px;
        }
        @include xs {
          align-items: flex-end;
        }
      }
      @include max-md {
        min-width: auto;
      }
      @include sm {
        flex: 1;
        max-width: initial;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        border-top: 1px solid $card-border;
        &:first-child {
          border-left: 0;
        }
      }
      @include xs {
        flex-direction: column;
        align-items: flex-end;
        width: 33.3%;
        min-width: initial;
        justify-content: initial;
        padding: 20px 8px 20px 6px;
        min-height: 153px;
      }
    }
    .hideProductInfoDiv {
      display: none;
    }
    .addToCart {
      margin-top: auto;
    }
    > div:last-child {
      flex-direction: column;
      justify-content: inherit;
      align-items: flex-end;
    }
  }
  .productQuantity {
    padding-bottom: 3px;
    > span:first-child {
      padding-right: 2px;
    }
  }
  &.listViewXl {
    border: 0;
    padding: 0;
    @include sm {
      min-width: 148px;
    }
    .productFooterInfo {
      @include default-padding();
      height: auto;

      @include xs {
        padding: 15px 10px;
      }
    }
    .noPaddingBottom {
      padding-bottom: 0;
    }
    .noPaddingTop {
      padding-top: 0;
    }
  }
  .switchProductFooter {
    margin-top: auto;
    width: 100%;
    .amountInfo {
      padding: 14px 12px;
      border-top: 1px solid $card-border;
      .amountList {
        > span {
          text-align: left;
        }
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
          padding-bottom: 10px;
        }
      }
    }
    @include lg {
      max-width: 192px;
    }
    @include md {
      max-width: 164px;
    }
    @include xs {
      display: none;
    }
  }

  .productFooterInfo {
    &.loginInfo {
      min-width: 232px;
      max-width: 232px;
      .alignItem {
        height: 100%;
        display: flex;
        width: 97%;
        @include xs {
          text-align: left;
          align-items: center;
          span {
            font-size: $small-font;
          }
        }
      }
      @include sm {
        min-height: auto;
        min-width: 100%;
        max-width: 100%;
      }
    }
    .productListViewContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include xs {
        align-items: flex-start;
      }
      &.gridView {
        align-items: flex-start;
      }
    }
  }

  &.gridView {
    .loginInfo {
      max-width: 100%;
      min-width: 100%;
      line-height: 16px;
    }
  }
}

.w100 {
  width: 100%;
}
.bulkBuy {
  margin-top: 5px;
  display: flex;
  max-height: 67px;
}

.zoomWrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;

  button {
    &:focus {
      box-shadow: $dark-gray 0px 0px 0px 1px inset;
      border-radius: 0;
    }
  }

  span {
    color: $zoom-color;
    font-size: $fsXl;
    width: auto;
    padding: 0;
  }
}

.isDelete {
  position: absolute;
  left: 10px;
  bottom: 10px;

  span {
    color: $zoom-color;
    font-size: $xxLg;
  }
}

.promoLabel {
  position: absolute;
  top: 4%;
  left: 4%;
  max-width: 70%;
}

.checkBoxWrapper {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.productHeaderActionsContainer {
  align-self: flex-start;
  min-height: 23px;
}

.bulkBuyButton {
  position: absolute;
  top: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
