@import 'assets/scss/mixins/breakpoints.scss';

.ratingContainer {
  padding-bottom: 5px;
  padding-top: 9px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > span {
    color: $light-gray;
    font-size: $small-font;
  }
}

.productRating {
  padding-bottom: 0px;
  padding-right: 8px;
  label {
    span {
      font-size: 20px;
    }
  }
}

.cursorDefault {
  label {
    cursor: default;
  }
}
.cursorPointer {
  label {
    cursor: pointer;
  }
}

// label {
//   margin: 0;
//   line-height: normal;
// }

.ratingStyle {
  position: relative;
  display: flex;
  margin-right: 4px;
}

.withFullStar {
  color: $light-gray;
}
.primary {
  > div {
    span {
      color: $link-color;
    }
  }
}
