@import 'assets/scss/mixins/breakpoints.scss';
.catalogWrapper {
  padding-bottom: 40px;
  .dialogHeader {
    background: $hover-bg;
    padding: 12px 20px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-family: $font-family-medium;
    }
    button {
      font-family: 'MotivasanRegular';
      font-size: $medium-font;
    }
  }
  .dialogContent {
    padding: 22px 17px 38px;
    .productContent {
      padding: 5px;
    }
  }

  .dFlex {
    padding: 40px 40px 0;
    display: flex;
    @include xs {
      padding: 40px 15px 0;
    }
    .iconSize {
      height: 24px;
      width: 24px;
      border-radius: 100%;
      font-size: $fs2xl;
      text-align: center;
      padding-top: 3px;
      margin-right: 15px;
      &.flyer {
        > span {
          color: $link-color;
        }
      }
      &.specialDelivery {
        span {
          color: $icon-blue-color;
        }
      }
      &.promo {
        span {
          color: $link-color;
        }
      }
      &.assembly {
        span {
          color: $icon-grey-color;
        }
      }
      &.contract {
        span {
          color: $success-color;
        }
      }
      &.shipSeprately {
        span {
          color: $dark-gray;
        }
      }
      &.sourcebook {
        span {
          color: $icon-grey-color;
        }
      }
      &.restricted {
        span {
          color: $icon-restricted;
        }
      }
      &.marketPrice {
        span {
          color: $icon-grey-color;
        }
      }
    }

    .iconDetailsInfo {
      .iconInfo {
        font-size: $fs2xl;
        display: block;
        font-family: $font-family-medium;
        color: $dark-gray;
      }
      .iconDecription {
        font-size: $lg;
        font-family: 'MotivasanRegular';
        color: $zoom-color;
        margin-top: 10px;
      }
    }
  }
}
