@import 'assets/scss/mixins/breakpoints.scss';

.minWidth {
  min-width: 60px;
}

.fieldContainer {
  @include min-xs() {
    flex-flow: row-reverse;
  }
  @include min-sm() {
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.loaderContainer {
  display: flex;
  margin-right: 10px;

  @include min-xs() {
    margin-left: 10px;
  }

  @include min-sm() {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    display: flex;
    margin-left: auto;
  }

  @include min-md() {
    position: static;
    left: auto;
    transform: none;
    top: auto;
  }
}
