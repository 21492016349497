@import 'assets/scss/mixins/breakpoints.scss';

.productDetail {
  padding: 10px 12px 10px;
  background-color: $white;
  &.listView,
  &.listViewXl,
  &.listViewLg {
    flex: 1;
    padding: 20px;
    @include md {
      padding: 20px 15px;
    }
    @include sm {
      padding: 20px 10px;
    }
    @include xs {
      max-width: 55%;
      padding: 15px 10px;
    }
  }
  &.gridViewSm {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.listViewLg {
    @include sm {
      width: 50%;
    }
  }
}
