@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/utils.scss';
.tooltip {
  background: $tooltip-bg;
  width: 220px;
  text-align: left;
  border-radius: 3px;
  @include ie {
    position: absolute !important;
  }
}
