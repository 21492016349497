@import 'assets/scss/mixins/breakpoints.scss';

.productContentTop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  button {
    color: $zoom-color;
    font-size: 16px;
    padding-left: 5px;
    margin-top: 4px;
    span {
      padding-right: 0;
    }
    @include xs {
      padding-left: 3px;
    }
  }
  h3 {
    line-height: normal;
    word-break: break-all;
    @include xxs {
      max-width: 65px;
    }
    @include xs {
      & + span {
        font-size: $small-font;
      }
    }
  }
  .inputFieldWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .inputField {
      width: 60px;
      margin-bottom: 6px;

      &.inputFieldGray {
        input {
          border: 1px solid $border-color;
        }
      }
      input {
        height: 30px;
        text-align: center;
      }
    }

    @include xs {
      span {
        font-size: $small-font;
      }
    }
  }
}

.productQuantityDetailsContentCenter {
  margin-top: 23px;
  margin-bottom: auto;
  @include sm {
    margin-left: auto;
    margin-top: inherit;
    margin-bottom: inherit;
  }
  @include xs {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.productQuantityItem {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2px;
  @include xs {
    * {
      font-size: $small-font;
    }
  }
}
