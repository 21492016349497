@import 'assets/scss/variables.scss';

@mixin iconColor($color) {
  button {
    span {
      color: $color;
    }
  }
}

.productUtilityIcon {
  .specialDelivery {
    @include iconColor($icon-blue-color);
  }

  .promo {
    @include iconColor($link-color);
  }

  .assembly {
    @include iconColor($icon-grey-color);
  }

  .flyer {
    @include iconColor($link-color);
  }

  .contract {
    @include iconColor($success-color);
  }

  .shipSeprately {
    @include iconColor($dark-gray);
  }

  .sourcebook {
    @include iconColor($icon-grey-color);
  }

  .restricted {
    @include iconColor($icon-restricted);
  }

  .marketPrice {
    @include iconColor($icon-grey-color);
  }

  .shipSeprately {
    @include iconColor($dark-gray);
  }

  > span {
    font-size: 16px;
    padding: 0 2px 0;
  }

  &.listView,
  &.listViewLg,
  &.listViewXl {
    padding: 5px 0 12px;
    min-height: 41px;
  }

  .btnIcon {
    display: inline-flex;
  }
}

.productPageIcons {
  span {
    font-size: 24px;
    margin-right: 10px;
    padding: 0;
  }
}

.popupIcon {
  background: none;
  border: 0;
  outline: 0;
  padding: 2px;
  font-size: $xxLg;
  line-height: normal;
  margin-right: 6px;
  span {
    padding: 0;
    display: flex;
  }
  &:focus {
    box-shadow: $dark-gray 0px 0px 0px 1px inset;
    border-radius: 0px;
  }
}
