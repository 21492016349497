@import 'assets/scss/mixins/breakpoints.scss';
.measureUnit {
  margin-top: -7px;
}
.headingFlex {
  display: flex;
  width: 100%;
  align-items: center;
  .toolTipInfo {
    div {
      width: auto;
    }
    display: flex;
    flex-direction: column;

    sup {
      margin-bottom: 5px;

      span {
        margin-bottom: 0;
        padding: 0;
        @include xs {
          margin: 0;
          padding-top: 0;
        }
      }
      @include xs {
        margin-bottom: 8px;
      }
    }
    &.toolTipPosition {
      flex-direction: inherit;

      sup {
        margin: 0 3px 2px 0;
      }
      .measureUnit {
        margin-top: 0;
      }
    }
  }
  &.listView {
    .unitWrapper {
      @include xs {
        line-height: normal;
        margin-top: 5px;
        align-items: flex-end;
        flex-direction: row;
      }
    }
    .measureUnit {
      @include xs {
        margin: 0 0 3px 5px;
      }
    }
  }
  .unitWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include xs {
      align-items: flex-start;
    }
    .productQuantity {
      padding-bottom: 3px;
      > span:first-child {
        padding-right: 2px;
      }
    }
  }
  h3 {
    margin-top: 5px;
    padding-right: 5px;
  }
  .originalPrice {
    font-size: $fs2xl;
    margin-right: 15px;
  }

  &.listView,
  &.listViewXl,
  &.listViewLg {
    justify-content: flex-end;
    .toolTipInfo {
      sup {
        margin-bottom: 15px;
        margin-left: 3px;
      }
    }
    @include xs {
      justify-content: flex-start;
    }
  }
  &.listViewLg {
    justify-content: flex-end;
    .toolTipInfo {
      sup {
        margin-bottom: 20px;
      }
    }
  }
  &.gridViewSm {
    padding: 2px 0 12px;
  }
  &.listViewXl {
    h3 {
      padding-right: 0;
    }
  }
  &.gridView {
    position: relative;
    .toolTipInfo {
      sup {
        position: absolute;
        top: -2px;
      }

      .measureUnit {
        margin-top: 8px;
      }
    }
  }
}

.savingPrice {
  font-size: $fs2xl;
}
