@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.productHeader {
  // grid view is the default
  padding: 10px;
  min-height: 207px;
  height: 207px;
  @include flex-center();

  &.gridViewSm {
    padding-bottom: 0;

    button {
      border: 0;
      background: none;
      padding: 0;
      color: $link-color;
      line-height: 18px;
      font-family: $font-family-medium;
    }
  }

  // list view
  &.listView,
  &.listViewLg,
  &.listViewXl {
    border-right: 1px solid $card-border;
    height: auto;
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include lg {
      min-width: 250px;
    }

    @include md {
      min-width: 200px;
    }

    @include sm {
      max-width: 50%;
      min-width: 150px;
    }

    @include xs {
      flex-basis: 45%;
      max-width: 45%;
      min-width: auto;
    }

    @include print {
      flex-basis: 25%;
    }
  }
}
