@import 'assets/scss/mixins/breakpoints.scss';

.productContentCenter {
  margin-top: 35px;
  h5 {
    @include xs {
      & + span {
        font-size: $small-font;
      }
    }
  }
  @include sm {
    margin-left: auto;
    margin-top: inherit;
    margin-bottom: inherit;
  }
  @include xs {
    margin-top: auto;
    margin-bottom: auto;
  }
}
